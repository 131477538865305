var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-plus")]),_vm._v("Thêm tài khoản ")],1)]}}]),model:{value:(_vm.newUserDialogShow),callback:function ($$v) {_vm.newUserDialogShow=$$v},expression:"newUserDialogShow"}},[_c('QuickNewUser')],1),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"margin-top":"20px"},attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.tableData,"item-key":"name"},scopedSlots:_vm._u([{key:"item.user_role",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm._f("roleStatusColor")(item.user_role),"label":"","text-color":"white"}},[_vm._v(_vm._s(_vm._f("roleStatusText")(item.user_role)))])]}},{key:"item.user_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm._f("userStatusColor")(item.user_status),"label":"","text-color":"white"}},[_vm._v(_vm._s(_vm._f("userStatusText")(item.user_status)))])]}},{key:"item.acc_ac",fn:function(ref){
var item = ref.item;
return [_c('v-btn-toggle',{attrs:{"dense":"","color":"#033"}},[_c('router-link',{attrs:{"to":'/admin/users/'+item.id}},[_c('v-btn',{attrs:{"depressed":"","small":"","color":"blue darken-1","text-color":"white"}},[_c('i',{staticClass:"fas fa-ellipsis-h",staticStyle:{"color":"white"}})])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }