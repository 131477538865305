<template>
  <v-card>
    <v-card-title class="headline grey lighten-2" primary-title>Thêm tài khoản mới</v-card-title>

    <v-card-text style="margin-top:20px">
      <v-text-field v-model="name" dense label="Tên tài khoản" outlined></v-text-field>
      <v-text-field v-model="password" dense label="Mật khẩu" outlined></v-text-field>
      <v-text-field v-model="rePassword" dense label="Nhập lại mật khẩu" outlined></v-text-field>
      <label style="
    font-size: 14px;
" for>
        <b>Quyền:</b>
      </label>
      <v-btn-toggle
        style="display: -webkit-inline-box;"
        v-model="role"
        tile
        color="deep-purple accent-3"
        group
      >
        <v-btn v-for="(item, index) in userRoleMap" :key="index" :value="index">{{item.text}}</v-btn>
      </v-btn-toggle>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" :loading="isLoading" @click="creatNewUserClick()">Tạo tài khoản mới</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { UserRoleMap } from "@/utils/mapping";
import {createNewUser} from '@/api/users'
import {showToast} from '@/utils/index.js'
export default {
  computed: {
    userRoleMap() {
      return UserRoleMap;
    },

  },
  methods:{
      creatNewUserClick(){




          if(!this.password||this.password.length<1||this.password!=this.rePassword){
              showToast(this,'error','Mật khẩu không hợp lệ');
              return;
          }
          if(!this.name||this.name.length<1){
              showToast(this,'error','Tên tài khoản không hợp lệ');
              return;
          }
          this.isLoading = true
          createNewUser({
              name:this.name,
              password:this.password,
              role:Number(this.role)
          }).then(res=>{
            this.$router.push('/admin/users/'+res.data.id);
              this.isLoading = false;


          }).catch(()=>{
              this.isLoading = false;
              showToast(this,'error','Không thành công, hãy thử tên tài khoản khác');
          })
      }
  },
  data() {
      return {
        name: "",
        password: "",
        rePassword: "",
        role:'1',
        isLoading: false
      };
    }
};
</script>