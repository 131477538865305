<template>
  <div>
    <v-dialog
      v-model="newUserDialogShow"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs"
          v-on="on" large color="primary">
        <v-icon small="" left="" >fas fa-plus</v-icon>Thêm tài khoản
      </v-btn>
      </template>
      <QuickNewUser></QuickNewUser>
    </v-dialog>
    
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="tableData"
      item-key="name"
      class="elevation-1"
      style="margin-top:20px"
    >
      <template v-slot:item.user_role="{ item }">
        <v-chip
          class="ma-2"
          :color="item.user_role|roleStatusColor"
          label
          text-color="white"
        >{{item.user_role|roleStatusText}}</v-chip>
      </template>
      <template v-slot:item.user_status="{ item }">
        <v-chip
          class="ma-2"
          :color="item.user_status|userStatusColor"
          label
          text-color="white"
        >{{item.user_status|userStatusText}}</v-chip>
      </template>
      <template v-slot:item.acc_ac="{ item }">
        <v-btn-toggle dense color="#033">
          <router-link :to="'/admin/users/'+item.id">
            <v-btn depressed small color="blue darken-1" text-color="white">
              <i class="fas fa-ellipsis-h" style="color:white"></i>
            </v-btn>
          </router-link>
        </v-btn-toggle>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import QuickNewUser from './components/QuickNewUser'
import { getAllUser } from "@/api/users";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
  components:{
    QuickNewUser
  },
  data() {
    return {
      isLoading: false,
      newUserDialogShow:false,
      headers: [
        { text: "Tên đăng nhập", value: "user_login" },
        { text: "Tên", value: "user_fullname" },
        { text: "SĐT", value: "user_phone" },
        { text: "Quyền", value: "user_role", align: "center" },
        { text: "Trạng thái", value: "user_status", align: "center" },
        { text: "Actions", value: "acc_ac" }
      ],
      tableData: []
    };
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Quản lý tài khoản", route: "/admin/users" },
        
      ]);
    this.isLoading = true;
    getAllUser().then(res => {
      this.tableData = res.data;
      this.isLoading = false;
    });
  }
};
</script>